'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaIncidentTracking.factory:ProposalList

 # @description

###
class ProposalListFactory
  ### @ngInject ###
  constructor: (
    $mdMedia
    $mdDialog
    $log
    ProposalManager
    Proposals
  ) ->
    ProposalListBase = {}
    ProposalListBase.show = (proposals, statuses, taskType, tasks, options = {} ) ->
      defaults =
        allowCancel: true,
        title: "app.proposals",
        fullScreen: "auto",
        useFullScreen: ($mdMedia('sm') || $mdMedia('xs')),
        onCancel: ()->
          $log.debug "Closed dialog"

      options = angular.extend {}, defaults, options

      $mdDialog.show
        controllerAs: 'proposalsList',
        resolve:
          options: () ->
            options
          title: () ->
            options.title
          proposals: ()->
            proposals
          statuses: ()->
            statuses
          tasks: ()->
            tasks
          taskType: ()->
            taskType

        controller: [
          '$scope'
          '$stateParams'
          '$mdDialog'
          'options'
          'proposals'
          'statuses'
          ($scope, $stateParams, $mdDialog, options, proposals, statuses, taskType) ->
            $scope.options = options
            $scope.title = options.title
            $scope.proposals = proposals
            $scope.statuses = statuses

            $scope.hide = () ->
              $mdDialog.hide()

            $scope.cancel = () ->
              $mdDialog.cancel()

            $scope.createTask = (unitId) ->
              $mdDialog.hide()

        ]
        templateUrl: 'lpa/lpa-incident-tracking/views/lpa-incident-proposal-list.tpl.html'
        parent: angular.element(document.body)
        clickOutsideToClose: true
        fullscreen: false
      .then (result) ->
        return ProposalManager.createTask proposals[result], taskType
      , () ->
        return options.onCancel()
        # Cancel

    return ProposalListBase

ProposalListFactory.$inject = [
  '$mdMedia',
  '$mdDialog',
  '$log',
  'ProposalManager',
  'Proposals'
]

angular
  .module 'lpaIncidentTracking'
  .factory 'ProposalList', ProposalListFactory
